<template>
    <div>
        <el-container class="ova-y">
            <el-main class="p0">
                <el-row>
                    <el-col :span="24">
                        <div class="box" ref="init">
                            <div class="box-content">
                                <el-form :inline="true" class="form-inline formBox">
                                    <el-form-item label="项目名称">
                                        <el-input style="width: 200px" size="medium" v-model="xmmc" placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <el-form-item label="项目性质">
                                        <el-cascader size="medium" style="width: 200px" v-model="checkedXmxz" :options="xmxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="项目类型">
                                        <el-cascader size="medium" style="width: 200px" v-model="checkedXmlx" :options="xmlxList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-button type="primary" style="margin-top:19px" size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
                                    <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">清空</el-button>
                                </el-form>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="plr12">
                    <el-col :span="24">
                        <div class="box">
                            <div class="box-title">
                                <span class="fright">
                                    <el-button @click="exportData" type="primary" size="mini" round icon="el-icon-upload2">导出</el-button>
                                    <el-button @click="drawer = true;getTotal();" type="primary" size="mini" round icon="el-icon-s-data">汇总</el-button>
                                </span>
                                <h3 class="tit">水运列表</h3>
                            </div>
                            <div class="box-content">
                                <div class="tj">
                                    <div class="total-item total-item-1">
                                        <img src="../../assets/icon/tj1.png" alt="">
                                        <div class="t">
                                            <h5>项目数量<small>（个）</small></h5>
                                            <b class="date-num">{{ total }}</b>
                                        </div>
                                    </div>
                                    <div class="total-item total-item-2">
                                        <img src="../../assets/icon/tj5.png" alt="">
                                        <div class="t">
                                            <h5>总投资<small>（亿元）</small></h5>
                                            <b class="date-num">{{ ztz.toFixed(1) }}</b>
                                        </div>
                                    </div>
                                    <div class="total-item total-item-5">
                                        <img src="../../assets/icon/tj5.png" alt="">
                                        <div class="t">
                                            <h5>十四五投资<small>（亿元）</small></h5>
                                            <b class="date-num">{{ sswtz.toFixed(1) }}</b>
                                        </div>
                                    </div>
                                </div>
                                <el-table @sort-change="changeSort" @row-dblclick="openDetails" :data="tableData" size="small " :height="tableHeight" border :default-sort="{ prop: 'XH', order: 'ascending' }" style="width: 100%">
                                    <el-table-column fixed prop="" label="操作" :width="130" show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <span class="newicon iconsyes" @click="openDetails(scope.row)"><i class="el-icon-info icons"></i>详情</span>
                                            <span class="newicon iconsyes" @click="openZp(scope.row)"><i class="el-icon-thumb icons"></i>指派</span>
                                            <!-- <i class="el-icon-postcard icons iconsyes" @click="openDetails(scope.row)"></i> -->
                                            <!-- <i class="el-icon-thumb icons iconsyes" style="margin-right: 0;color:#f00" @click="openZp(scope.row)"></i> -->
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="XMMC" label="项目名称" show-overflow-tooltip :width="350" sortable></el-table-column>
                                    <el-table-column prop="XMXZ" label="项目性质" :width="150" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="XMLX" label="项目类型" :width="150" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="ZTZ" label="总投资（亿元）" :width="150" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="SSWTZ" label="“十四五”投资（亿元）" :width="180" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="JSGM" label="建设规模" show-overflow-tooltip></el-table-column>
                                </el-table>
                                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                                </el-pagination>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
        <el-drawer title="汇总" :visible.sync="drawer" :direction="direction" size="650px">
            <Hz :totalXmxz="totalXmxz" :totalXmlx="totalXmlx" />
        </el-drawer>
        <el-dialog :title="dialogDetails.XMMC" :visible.sync="dialogTableVisible">
            <div class="mb15">
                <el-button type="primary" v-if="btnviewshow" @click="handleChange1" size="small">完善信息</el-button>
                <el-button type="primary" v-if="btneditshow" @click="handleChange2(), submit()" size="small">保存</el-button>
                <el-button v-if="btneditshow" size="small" @click="handleChange2">取消</el-button>
            </div>
            <div class="view-card-info" v-if="viewshow">
                <div class="mycard-lg">
                    <table class="table">
                        <tr>
                            <th width="150">项目名称</th>
                            <td colspan="3">{{ dialogDetails.XMMC }}</td>
                        </tr>
                        <tr>
                            <th width="150">项目性质</th>
                            <td>{{ dialogDetails.XMXZ }}</td>
                            <th width="150">项目类型</th>
                            <td>{{ dialogDetails.XMLX }}</td>
                        </tr>
                        <tr>
                            <th>总投资</th>
                            <td>{{ dialogDetails.ZTZ }}</td>
                            <th>“十四五”投资</th>
                            <td>{{ dialogDetails.SSWZTZ }}</td>
                        </tr>
                        <tr>
                            <th>建设规模</th>
                            <td colspan="3">{{ dialogDetails.JSGM }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="edit-card-info" v-if="editshow">
                <el-alert title="填报完信息记得点击保存按钮" type="error" class="mb15">
                </el-alert>
                {{userid}}
                <el-form ref="formdata" :model="formdata">
                    <div class="mycard-lg">
                        <table class="table">
                            <tr>
                                <th width="150">项目名称</th>
                                <td colspan="3">
                                    <el-input v-model="formdata.XMMC" disabled></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th width="150">项目性质</th>
                                <td>
                                    <el-input v-model="formdata.XMXZ" disabled> </el-input>
                                </td>
                                <th width="150">项目类型</th>
                                <td>
                                    <el-input v-model="formdata.XMLX" disabled></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>总投资</th>
                                <td>
                                    <el-input v-model="formdata.ZTZ"></el-input>
                                </td>
                                <th>“十四五”投资</th>
                                <td>
                                    <el-input v-model="formdata.SSWZTZ"></el-input>
                                </td>
                            </tr>
                            <tr>
                                <th>建设规模</th>
                                <td colspan="3">
                                    <el-input v-model="formdata.JSGM"></el-input>
                                </td>
                            </tr>
                        </table>
                    </div>
                </el-form>
            </div>
        </el-dialog>
        <el-drawer :title="jsdwXmInfo.XMMC+'-建设单位'" :visible.sync="jsdwshow" :direction="'rtl'" size="80%">
            <el-main>
                <el-form :inline="true" class="form-inline">
                    <el-form-item>
                        <el-input style="width: 200px" size="medium" v-model="searchParams.dwmc" placeholder="单位名称"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input style="width: 200px" size="medium" v-model="searchParams.xmzrr" placeholder="项目负责人"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input style="width: 200px" size="medium" v-model="searchParams.xmlxr" placeholder="项目联系人"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="medium" icon="el-icon-search" @click="jsdwGetData">查询</el-button>
                        <el-popconfirm style="margin:0 10px" confirm-button-text='确认' @confirm="jsdwZp()" cancel-button-text='取消' icon="el-icon-info" icon-color="red" title="是否确认指派?">
                            <el-button slot="reference" type="info" size="medium"> 指派</el-button>
                        </el-popconfirm>
                        <el-button type="success" size="medium" @click="jsdwopenDetails()"><i class="fa fa-plus"></i> 新增</el-button>
                    </el-form-item>
                </el-form>
                <el-table ref="multipleTable" :row-class-name="tableRowClassName" v-if="jsdwData.length>0" @row-dblclick="jsdwopenDetails" :data="jsdwData" size="small " border style="width: 100%;margin-top:20px;" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55" :selectable="checkSelectable"></el-table-column>
                    <el-table-column prop="DWMC" label="单位名称" show-overflow-tooltipsortable></el-table-column>
                    <el-table-column prop="XMZRR" label="项目负责人" sortable show-overflow-tooltip></el-table-column>
                    <el-table-column prop="XMZRRLXDH" label="联系电话" sortable show-overflow-tooltip></el-table-column>
                    <el-table-column prop="XMLXR" label="项目联系人" sortable show-overflow-tooltip></el-table-column>
                    <el-table-column prop="XMLXRLXDH" label="联系电话" sortable show-overflow-tooltip></el-table-column>
                    <el-table-column prop="" label="操作" :width="120" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-button style="margin-right:5px;" slot="reference" icon="el-icon-edit" type="primary" size="mini" circle @click="jsdwopenDetails(scope.row)"></el-button>
                            <el-popconfirm confirm-button-text='确认' @confirm="jsdwdelete(scope.row)" cancel-button-text='取消' icon="el-icon-info" icon-color="red" title="是否删除建设单位？">
                                <el-button slot="reference" icon="el-icon-delete" type="danger" size="mini" circle></el-button>
                            </el-popconfirm>
                            <!-- <div v-show="userName == scope.row.Creator"> -->
                            <!-- </div> -->
                        </template>
                    </el-table-column>
                </el-table>
                <el-empty description="暂无建设单位" v-else></el-empty>
                <!-- <el-pagination background @size-change="jsdwSizeChange" @current-change="jsdwCurrentChange" :current-page="jsdwpage" :page-sizes="[30, 50, 100, 400]" :page-size="jsdwpagesize" layout="total, sizes, prev, pager, next, jumper" :total="jsdwtotal">
                </el-pagination> -->
            </el-main>
            <el-drawer :title="jsdwdetails.DWMC" :append-to-body="true" :visible.sync="jsdwinnerDrawer" size="40%">
                <el-main>
                    <el-form ref="form" label-width="100px">
                        <el-form-item label="单位名称">
                            <el-input v-model="jsdwdetails.DWMC"></el-input>
                        </el-form-item>
                        <el-form-item label="项目负责人">
                            <el-input v-model="jsdwdetails.XMZRR"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话">
                            <el-input v-model="jsdwdetails.XMZRRLXDH"></el-input>
                        </el-form-item>
                        <el-form-item label="项目联系人">
                            <el-input v-model="jsdwdetails.XMLXR"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话">
                            <el-input v-model="jsdwdetails.XMLXRLXDH"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit">提交</el-button>
                            <el-button @click="jsdwinnerDrawer=false">取消</el-button>
                        </el-form-item>
                    </el-form>
                </el-main>
            </el-drawer>
        </el-drawer>
    </div>
</template>
<script>
import Hz from "@/components/Query/Water/Hz.vue"; //汇总
import { exportData } from "../../js/util/export.js"; //导出表格

export default {
    name: "Home",
    components: {
        Hz,
    },
    data() {
        return {
            userName: "",
            jsdwpage: 1,
            jsdwpagesize: 3000,
            jsdwtotal: 0,
            jsdwmultipleSelection: [],
            jsdwGx: {
                project: {
                    xmid: "",
                    xmlx: 1,
                },
                jsdwIDList: [],
            },
            selectTable: [],
            searchParams: {
                dwmc: "",
                xmzrr: "",
                xmlxr: "",
            },
            searchData: [
                { Name: "XMLX", Value: "Water", DisplayType: "Equal" },
            ],
            jsdwvisible: false,
            jsdwData: [],
            jsdwinnerDrawer: false,
            jsdwdetails: {},
            jsdwshow: false,
            jsdwXmInfo: {},

            viewshow: true,
            editshow: false,
            btnviewshow: true,
            btneditshow: false,
            formdata: {},
            tableHeight: 0, //表格高度
            activeIndex: true, //显隐高级搜索
            dialogTableVisible: false,
            dialogDetails: {},
            /**查询条件 begin*/
            xmmc: "", //项目名称
            xmnd: "", //项目年度
            checkedXmxz: [], //项目性质
            checkedXmlx: [], //项目类型
            /**查询条件 end*/
            xmxzList: [
                {
                    value: "续建项目",
                    label: "续建项目",
                    disabled: false,
                },
                {
                    value: "“十四五”新开工项目",
                    label: "“十四五”新开工项目",
                    disabled: false,
                },
                {
                    value: "“十四五”跨“十五五”",
                    label: "“十四五”跨“十五五”",
                    disabled: false,
                },
                {
                    value: "谋划项目",
                    label: "谋划项目",
                    disabled: false,
                },
            ],
            xmlxList: [
                {
                    value: "航道项目",
                    label: "航道项目",
                    disabled: false,
                },
                {
                    value: "港口项目",
                    label: "港口项目",
                    disabled: false,
                },
                {
                    value: "航电枢纽",
                    label: "航电枢纽",
                    disabled: false,
                },
                {
                    value: "支持系统",
                    label: "支持系统",
                    disabled: false,
                },
            ],
            currentPage: 1, //当前页
            pageSize: 30, //每页记录数
            isIndeterminateXmxz: true,
            isIndeterminateXmlx: true,
            drawer: false,
            direction: "rtl",
            tableData: [], //请求列表
            total: 0, //请求记录数
            ztz: 0,
            sswtz: 0,
            totalXmxz: [], //汇总按项目性质
            totalXmlx: [], //汇总按项目类型
            xmlx: "航道项目",
            sortData: {
                field: "XH",
                sort: "asc",
            },
        };
    },
    mounted() {
        var that = this;
        let height = this.$refs.init.offsetHeight;
        setTimeout(() => {
            that.tableHeight = document.body.offsetHeight - height - 215;
        }, 100);
        this.search();
        this.userName = this.$store.getters.getUserInfo().userName;
    },
    methods: {
        changeSort(val) {
            if (val.order) {
                this.sortData = {
                    field: val.prop,
                    sort: val.order == "ascending" ? "asc" : "desc",
                };
                if (val.prop == "SSDS") {
                    this.sortData.field = "DSBM";
                }
                if (val.prop == "SSX") {
                    this.sortData.field = "QXBM";
                }
            } else {
                this.sortData = {
                    field: "XMXH",
                    sort: "asc",
                };
            }
            this.tableData = [];
            this.search();
        },
        tableRowClassName(row, index) {
            return this.checkSelectable(row.row) ? "" : "hidden";
        },
        //检测建设单位是否可选
        checkSelectable(row) {
            if (
                (this.searchParams.dwmc &&
                    row.DWMC.indexOf(this.searchParams.dwmc) < 0) ||
                (this.searchParams.xmzrr &&
                    row.XMZRR.indexOf(this.searchParams.xmzrr) < 0) ||
                (this.searchParams.xmlxr &&
                    row.XMLXR.indexOf(this.searchParams.xmlxr) < 0)
            ) {
                return false;
            } else {
                return true;
            }
            // return true;
            // var ids = [];
            // this.selectTable.forEach((item) => {
            //     ids.push(item.jsdwid);
            // });
            // if (ids.indexOf(row.ID) >= 0) {
            //     return false;
            // } else {
            //     return true;
            // }
        },
        //指派
        jsdwZp() {
            if (this.jsdwmultipleSelection.length < 1) {
                this.$message("未选择要指派的建设单位");
                return false;
            }
            this.jsdwGx.jsdwIDList = [];
            this.jsdwGx.project.xmid = this.jsdwXmInfo.ID;

            var ids = [];
            this.selectTable.forEach((item) => {
                ids.push(item.jsdwid);
            });

            this.jsdwmultipleSelection.forEach((item) => {
                // if (ids.indexOf(item.ID) < 0) {
                this.jsdwGx.jsdwIDList.push(item.ID);
                // }
            });
            this.http
                .post(
                    "/api/Plan_buildcompany_project/AddBuildCompanyProject",
                    this.jsdwGx
                )
                .then((res) => {
                    this.$message(res.message);
                    this.jsdwGetData();
                });
        },
        //table复选框事件
        handleSelectionChange(val) {
            this.jsdwmultipleSelection = val;
        },
        //删除建设单位
        jsdwdelete(item) {
            this.http
                .post("/api/Plan_buildcompany/DeleteBuildcompany", item)
                .then((res) => {
                    this.$message(res.message);
                    this.jsdwGetData();
                });
        },
        //建设单位新增/编辑
        onSubmit() {
            var url = "/api/Plan_buildcompany/AddBuildcompany?xmlx=1";
            if (this.jsdwdetails.ID != "00000000-0000-0000-0000-000000000000") {
                url = "/api/Plan_buildcompany/UpdateBuildcompany?xmlx=1";
            }
            this.http.post(url, this.jsdwdetails).then((res) => {
                this.$message(res.message);
                this.jsdwinnerDrawer = false;
                this.jsdwGetData();
            });
        },
        //建设单位表单初始化
        jsdwopenDetails(row) {
            this.jsdwdetails = {
                ID: "00000000-0000-0000-0000-000000000000",
                CreateID: 0,
                Creator: "",
                CreateDate: "",
                ModifyID: 0,
                Modifier: "",
                ModifyDate: "",
                DWMC: "",
                XMZRR: "",
                XMZRRLXDH: "",
                XMLXR: "",
                XMLXRLXDH: "",
                XMID: this.jsdwXmInfo.ID,
            };
            if (row) {
                this.jsdwdetails = JSON.parse(JSON.stringify(row));
            }
            this.jsdwinnerDrawer = true;
        },
        //获取建设单位列表
        jsdwGetData() {
            var postData = {
                page: this.jsdwpage,
                rows: this.jsdwpagesize,
                wheres: JSON.stringify(this.searchData),
            };
            this.http
                .post("/api/Plan_buildcompany/GetPageData", postData)
                .then((res) => {
                    this.jsdwData = res.rows;
                    this.jsdwtotal = res.total;
                    this.checkSelect(res.rows);
                });
        },
        //检查建设单位与项目关系并在列表中选中
        checkSelect(data) {
            this.http
                .post(
                    "/api/Plan_buildcompany_project/getJsdwByXmid?XMID=" +
                        this.jsdwXmInfo.ID,
                    {}
                )
                .then((res) => {
                    this.selectTable = res.data;
                    data.forEach((row) => {
                        res.data.forEach((r) => {
                            if (row.ID == r.jsdwid) {
                                this.$refs.multipleTable.toggleRowSelection(
                                    row
                                );
                            }
                        });
                    });
                });
        },
        jsdwSizeChange(val) {
            this.jsdwpagesize = val;
            this.jsdwGetData();
        },
        jsdwCurrentChange(val) {
            this.jsdwpage = val;
            this.jsdwGetData();
        },
        //打开指派
        openZp(item) {
            this.jsdwXmInfo = item;
            this.jsdwGetData();
            this.jsdwshow = true;
        },
        handleChange1() {
            this.viewshow = false;
            this.editshow = true;
            this.btneditshow = true;
            this.btnviewshow = false;
        },
        handleChange2() {
            this.viewshow = true;
            this.editshow = false;
            this.btneditshow = false;
            this.btnviewshow = true;
        },
        submit() {
            this.http
                .post("/api/Plan_water/UpdateWater", this.formdata)
                .then((res) => {
                    if (res.message == "修改成功") {
                        this.$message("修改成功");
                        this.search();
                        this.handleChange2();
                        this.openDetails(this.formdata);
                    }
                });
        },
        openDetails(row) {
            this.xmlx = row.XMLX;
            this.dialogDetails = row;
            this.dialogTableVisible = true;
            this.formdata = JSON.parse(JSON.stringify(row));
            this.handleChange2();
        },
        exportData() {
            exportData("Plan_water", this.postData());
        },
        //查询
        search() {
            this.getPageTotal(); //查汇总
            this.http
                .post(
                    "/api/Plan_water/GetData",
                    this.postData(),
                    "正在请求数据，请稍候..."
                )
                .then((res) => {
                    if (res.status == 0) {
                        this.total = res.total;
                        this.tableData = res.rows;
                    }
                });
        },
        //汇总
        getPageTotal() {
            this.http
                .post("/api/Plan_water/GetPageTotal", this.postData())
                .then((res) => {
                    this.ztz = res.ztz;
                    this.sswtz = res.sswtz;
                });
        },
        postData() {
            //项目名称
            var query_xmmc = {
                Name: "XMMC",
                Value: this.xmmc,
                DisplayType: "like",
            };
            //项目年度
            var query_xmnd = {
                Name: "XMND",
                Value: this.xmnd,
                DisplayType: "Equal",
            };

            //项目性质
            var query_xmxz = {
                Name: "XMXZ",
                Value: this.checkedXmxz.join(","),
                DisplayType: "checkbox",
            };
            //项目类型
            var query_xmlx = {
                Name: "XMLX",
                Value: this.checkedXmlx.join(","),
                DisplayType: "checkbox",
            };

            var postData = {
                page: this.currentPage,
                rows: this.pageSize,
                Sort: this.sortData.field,
                Order: this.sortData.sort,
                wheres: JSON.stringify([
                    query_xmmc,
                    query_xmnd,
                    query_xmxz,
                    query_xmlx,
                ]),
            };
            return postData;
        },
        clearCondition() {
            this.xmmc = "";
            this.xmnd = "";
            this.checkedXmxz = [];
            this.checkedXmlx = [];
        },
        getTotal() {
            //汇总
            this.http
                .post("/api/Plan_water/GetTotal", this.postData())
                .then((total) => {
                    this.totalXmxz = total.xmxz;
                    this.totalXmlx = total.xmlx;
                });
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.search();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.search();
        },
    },
    watch: {},
};
</script>
<style lang="less">
.hidden {
    display: none;
}
</style>
